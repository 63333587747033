


#content-container {
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    header {
        top: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 200px;
    }
  
    h2 {
        margin: 0;
        margin-top: 20px;
        font-family: 'Staatliches', sans-serif;
        color: #BF0628;
        font-size: 36px;
        font-weight: 400;
    }
}
