div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        margin: 0;
        font-family: 'Open Sans', sans-serif;
        color: #260101;
        font-size: 75px;
        font-weight: 400;
    }
    
    #button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 170px;
    }
}

