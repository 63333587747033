.button-container {
  align-items: center;
  border: none;
  display: flex;
  background-color: transparent;
  justify-content: center;

  

  .icon {
    width: 30px;
    height: 30px;
  }
}

.is-disabled {
  opacity: 25%;
}

.button-container:not(.tab-outlining):focus {
  outline: none;
}

.tab-outlining:focus {
  outline-color: #BF0628;
}
