#overlay-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #00000033;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 1;

  #settings-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 10px;
    width: 550px;
    height: 281px;
    padding: 17px;

    #settings-option-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      flex: 1;
      width: 100%;

      .setting-option {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        h4 {
          margin: 0 0 20px 0;
          font-family: 'Staatliches', sans-serif;
          color: #BF0628;
          font-size: 30px;
          line-height: 38px;
          font-weight: 400;
        }

        .session-controls {
          display: flex;
          flex-direction: row;
          align-items: center;

          h3 {
            margin: 0;
            font-family: 'Open Sans', sans-serif;
            line-height: 68px;
            color: #260101;
            font-size: 50px;
            font-weight: 400;
          }

        }
      }
    }

    #apply {
      width: 100%;
      height: 45px;
      background-color: #D92b4b;
      border: none;
      color: white;
      font-family: 'Staatliches', sans-serif;
      font-size: 24px;
      line-height: 32px;
    }
  }
}
